* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

header {
  display: flex;
  position: sticky;
  top: 0;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #5a3921;
  color: #fff;
}

header .logo {
  font-size: 1.5em;
  font-family: "Coiny-Regular";
  text-decoration: none;
  color: #ffffff;
}

header nav ul {
  display: flex;
  list-style: none;
}

header nav ul li {
  margin: 0 15px;
}

header nav ul li a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s;
}

header nav ul li a:hover {
  color: #d4a373;
}

.hero {
  font-family: "Coiny-Regular";
  height: 92vh;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
}

.hero video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: brightness(50%);
  background-color: #111;
}

.hero h1 {
  font-size: 3em;
  margin-bottom: 10px;
}

.hero p {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.hero .button, .hero .button-secondary {
  padding: 10px 20px;
  border: none;
  text-decoration: none;
  color: #fff;
  font-size: 1.2em;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.hero .button {
  background-color: #d4a373;
  margin-right: 10px;
}

.hero .button:hover {
  background-color: #b67c4c;
}

.hero .button-secondary {
  background-color: #5a3921;
}

.hero .button-secondary:hover {
  background-color: #3c2615;
}

/* About Section */
#onas {
  height: 100%;
  padding: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  z-index: 1;

  text-align: center;
  background-color: #fdf4e3;
  color: #5a3921;
}

#onas h2 {
  margin-bottom: 20px;
  font-size: 2em;
}

#onas p {
  font-size: 1.2em;
  line-height: 1.6;
  max-width: 80vw;
}

/* Products */
#produkty {
  height: 100vh;
  padding: 40px;
  background-color: #fff;
  text-align: center;
}

#produkty h2 {
  margin-bottom: 20px;
  font-size: 2em;
  color: #5a3921;
}

.product-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.product {
  background-color: #fdf4e3;
  border: 1px solid #d4a373;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: 30vw;
}

.product img {
  width: 15vw;
  height: 15vw;
  border-radius: 10px;
  margin-bottom: 10px;
}

h4 {
  margin: 2vh 0vw;
}

.product h3 {
  margin-bottom: 10px;
  color: #5a3921;
}

.product p {
  font-size: 0.9em;
  margin-bottom: 15px;
}

.product button {
  background-color: #d4a373;
  border: none;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.product button:hover {
  background-color: #b67c4c;
}

/* Footer */
footer {
  padding: 20px;
  background-color: #5a3921;
  color: #fff;
  text-align: center;
}

footer .social-media a {
  color: #d4a373;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s;
}

footer .social-media a:hover {
  color: #fff;
}

/* productpage */
.productPage {
  height: 86vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
}

.productPage .container {
  height: 80vh;
  width: 70vw;

  padding: 2vh 2vw;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: #fdf4e3;
  border: 1px solid #d4a373;
  border-radius: 10px;
}

.productPage .container img {
  height: 30vw;
  width: 30vw;

  border-radius: 2vw;
}

.productPage .container .desc {
  margin-left: 3vw;
}

.productPage .container .desc * {
  margin-top: 1vh;
}

.productPage .container .contents {
  margin-left: 0.5vw;

  display: flex;
  flex-direction: row;
}

.productPage .container .contents * {
  margin-left: 0.3vw;
}

.compPage {
  padding: 10px;
}

.compPage button {
  background-color: #4695D6;
  color: white;
  transition-duration: 0.3s;
  border: 2px solid #4695D6;
  padding: 5px;
  border-radius: 5px;
}

.compPage button:hover {
  background-color: white;
  border: 2px solid #4695D6;
  color: black;
}

.contact-page {
  margin: 10px;
  padding: 20px;
  max-width: 800px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-title {
  text-align: center;
  color: #6b4f31;
  font-size: 2.4rem;
  margin-bottom: 10px;
}

.contact-description {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 20px;
}

.contact-details h2 {
  color: #6b4f31;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.contact-details p {
  font-size: 1rem;
  margin: 5px 0;
}

.contact-page button {
  background-color: #5a3921;
  color: white;
  transition-duration: 0.3s;
  border: 2px solid #5a3921;
  padding: 5px;
  border-radius: 5px;
}

.contact-page button:hover {
  background-color: white;
  border: 2px solid #5a3921;
  color: black;
}

.about-us-page {
  margin: 0 auto;
  padding: 20px;
  margin: 10px;
  max-width: 900px;
  background-color: #fffdf7;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-us-title {
  text-align: center;
  font-size: 2.5rem;
  color: #6b4f31;
  margin-bottom: 20px;
}

.about-us-intro, .about-us-story, .about-us-values, .about-us-mission {
  margin-bottom: 20px;
}

.about-us-intro p {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 15px;
}

.about-us-story h2, .about-us-values h2, .about-us-mission h2 {
  font-size: 1.8rem;
  color: #6b4f31;
  margin-bottom: 10px;
}

.about-us-story p, .about-us-mission p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.about-us-values ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.about-us-values li {
  font-size: 1rem;
  margin: 10px 0;
  padding-left: 20px;
  position: relative;
}

.about-us-values li::before {
  content: "✔";
  color: #6b4f31;
  position: absolute;
  left: 0;
  font-size: 1.2rem;
}

.about-us-page strong {
  color: #6b4f31;
}

